<template>
  <div>
    <h5>{{$t('preview.active_days_week')}}</h5>
    <apexchart
      type="bar"
      height="250"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <h3 class="text-center mb-3">{{$t('preview.annual_avg')}} <strong>{{data['30d'].value}} {{$t('preview.days')}}</strong></h3>
  </div>
</template>

<script>
import apexchart from "vue-apexcharts";

export default {
  components: {
    apexchart,
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    categories() {
      return Object.keys(this.data);
    },
    active_days_per_week() {
      const values_days = [];
      values_days.push(this.data['30d'].value);
      values_days.push(this.data['90d'].value);
      values_days.push(this.data['180d'].value);
      return values_days;
    }
  },
  data() {
    return {
      days: [30, 90, 180],
      series: [
        {
          name: "active_days_peer_week",
          data: this.active_days_per_week(),
        },
      ],
      chartOptions: {
        tooltip: {
          custom: ({ seriesIndex, dataPointIndex, w}) => {
            return (
              `<div class="width-tooltip-graph">
                  <div class="p-1">
                    <strong class="mr-tooltip-graph"> ${this.$t('preview.active_days_week')} ${this.$t('preview.in_last')}  ${this.chartOptions.xaxis.categories[dataPointIndex]}: 
                    </strong> ${w.globals.initialSeries[seriesIndex].data[dataPointIndex]}
                  </div>
                </div>`
            )
          }
        },
        colors: ['#7D5AC9'],
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 200,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: false,
        },

        xaxis: {
          categories: [`30 ${this.$t('twitter.days')}`, `90 ${this.$t('twitter.days')}`, `180 ${this.$t('twitter.days')}`],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
          },
        },
      },
    }
  }
}
</script>